<template >
  <el-dialog :show-close="true"	  custom-class="minWidthLandAdd" title="身份验证" :visible.sync="visible" @close="onClose" :close-on-click-modal="false"  append-to-body   draggable>
    <div style="text-align: left">

    </div>
    <div></div>
    <div style="text-align: center; padding-top:10px ">
     <!-- <img src="../../assets/img/sweep-success.png" width="160" >-->
      <img src="../../assets/2.png" width="320" />
      <div style="color: black;font-size: 16px;padding-top:20px ">
        请使用<span style="color: green;font-weight: bold">登录手机号</span> 微信扫码(进行实名认证)
      </div>
    </div>
  </el-dialog>
</template>

<script setup  name="needAdd">
import { useDict } from '@/hooks/dict';
import { reactive, watch, ref, nextTick, computed, defineAsyncComponent } from 'vue'
import {Message} from 'element-ui'
import {getObj, addObj, putObj,setidCardInfo,getidCardInfo} from '@/service/CommonService';
import { rule } from '@/utils/validator';
import { preventReClick } from '@/utils/preventReClick'

const emit = defineEmits(['refresh']);
const mapDialogRef = ref();

const openMapDialog = () => {
  mapDialogRef.value.openDialog((data) => {
    console.log(data);
  });
};

// 定义变量内容
const dataFormRef = ref();
const visible = ref(false);
const errorShow = ref(false);
const loading = ref(false);
const callbackFunc = ref(function () {

});


const form = reactive({
  username: "",
  verify_ckb:""
})

const onClose= (id) =>{
  callbackFunc.value(false);
  visible.value = false;
}
const changeErr = () =>{
  if (form.verify_ckb) {
    errorShow.value = false;
    return;
  }
};

// 打开弹窗
const openDialog = (address, geoInfo, callback) => {
  visible.value = true;

  callbackFunc.value = callback;
  form.verify_ckb='';
  nextTick(() => {
    dataFormRef.value?.resetFields();
  });
  // 获取landInfo信息
};


const onSubmit = () => {
  if (!form.verify_ckb) {
    errorShow.value = true;
    return;
  }
  if (callbackFunc.value) {
    callbackFunc.value(form.verify_ckb);
  }
  visible.value = false;
}




const forms = reactive({
  timer:null,
  userverify:false
})

const closeDialog = () => {
  visible.value = false;
};
// 暴露变量
defineExpose({
  openDialog,
  closeDialog,
  stop
});
</script>

<style>
.minWidthLandAdd{
  min-width: 1260px !important;
}
</style>
